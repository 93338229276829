import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Entity } from '@proman/services/entity.service';
import { FilterService } from '@proman/services/filter.service';
import { camelCase } from '@proman/utils';

@Component({
    selector: 'pm-metadata-path-builder-dialog',
    template: `
        <pro-dialog-title [title]="entity"></pro-dialog-title>
        <div mat-dialog-content>
            <pro-editable-container label="value"><span style="font-size: 14px;font-weight: bolder;">{{ value }}</span></pro-editable-container>
            <pro-select [value]="field"
                    [config]="{ label: 'field', disableSearch: true }"
                    [options]="fields"
                    (onChange)="setField($event)"></pro-select>
        </div>
        <pro-dialog-actions (callback)="confirm()"
                           [isCallbackDisabled]="!isLastFieldString"
                           variant="confirm"></pro-dialog-actions>
    `
})

export class MetadataPathBuilderDialogComponent {
    entity: any;
    metadataEntity: any;
    dynamicTableEntity: any;
    fields: any;
    field: any;
    value: string;
    isLastFieldString: boolean;

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
        private Entity: Entity,
        private Filter: FilterService,
        private dialogRef: MatLegacyDialogRef<MetadataPathBuilderDialogComponent>,

    ) {
        this.entity = this.data.entity;
        this.metadataEntity = this.Entity.get('public/metadata');
        this.dynamicTableEntity = this.Entity.get('dynamic_table');
        this.value = camelCase(this.entity);

        this.getFields(this.entity);
    }

     getFields(table: string) {
        if (table.startsWith('dt_')) {
            this.dynamicTableEntity.get({ id: table.substring(3), join: ['columns'] })
                .then((response: any) => this.setFields(response.columns, 'dynamic'));

        } else if (table) {
            this.metadataEntity
                .customizedRequest(table)
                .then((response: any) => this.setFields(response.entity, 'metadata'));

        }

    }

    setFields(fields: any, tableType: string) {
        if (!fields) return;

        this.fields = [];

        if (tableType === 'metadata') {
            for (let id in fields) {
                const type = fields[id];
                const name = this.Filter.translate(id);

                this.fields.push({ id, name, type });

            }

        } else if (tableType === 'dynamic') {
            for (let field of fields) {
                const type = field.type;
                const name = field.name;
                const id = field.columnName;

                this.fields.push({ id, name, type });

            }

        }

    }

    setField(value: any) {
        if (this.isLastFieldString && this.value.split('.').length > 1) {
            this.value = this.value.substring(0, this.value.lastIndexOf('.'));
        }

        this.value += `.${value.id}`;

        if (!(typeof value.type === 'string')) {
            this.getFields(value.type.target);
            this.isLastFieldString = false;
        } else {
            this.isLastFieldString = true;
        }

    }

   confirm() {
        this.dialogRef.close(this.value);
   }

}
